import { Field as BaseField, Label, Control } from '@radix-ui/react-form';
import { Strong, Text, Switch } from '@/components/radix';
import type { FieldProps } from './types';

export interface SwitchFieldProps
  extends Omit<FieldProps<boolean>, 'defaultValue' | 'name'> {
  name: string;
  description?: string;
  disabled?: boolean;
}

export function SwitchField({
  name,
  label,
  description,
  ...props
}: SwitchFieldProps) {
  return (
    <BaseField name={name} className="flex flex-nowrap items-start gap-4">
      <div className="gap flex flex-1 flex-col">
        <Label asChild>
          <Text as="label" size="3">
            <Strong>{label}</Strong>
          </Text>
        </Label>

        <Text>{description}</Text>
      </div>

      <Control asChild>
        <Switch {...props} />
      </Control>
    </BaseField>
  );
}

import { RadioGroup } from '@headlessui/react';
import * as Form from '@radix-ui/react-form';
import { Badge, Card, Strong, Text } from '@/components/radix';
import {
  PROPERTY_NAME_FIELD,
  SERVICE_PACKAGE_FIELD_NAME,
} from '@/constants/client/field-names';
import { NameField, type NameFieldProps } from './names';
import { HiddenField } from './Hidden';
import { formatCurrency } from '@/lib/utils/i18n';

const i18n = {
  en: {
    tags: {
      recommend: 'Recommended',
      popular: 'Popular',
    },
    fields: {
      propertyName: {
        label: 'Property Name',
        placeholder: 'property name',
      },
      servicePackages: {
        label: 'Service Packages',
      },
    },
    actions: {
      submit: 'Continue',
    },
  },
};

export function PropertyNameField({
  name = PROPERTY_NAME_FIELD,
  label = i18n.en.fields.propertyName.label,
  placeholder = i18n.en.fields.propertyName.placeholder,
  ...props
}: NameFieldProps) {
  return (
    <NameField name={name} label={label} placeholder={placeholder} {...props} />
  );
}

export interface ServicePackagesFieldProps {
  name?: string;
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options?: {
    name: string;
    description?: string;
    price?: string;
    tags?: ('recommend' | 'popular')[];
  }[];
}

export function ServicePackagesField({
  name = SERVICE_PACKAGE_FIELD_NAME,
  defaultValue,
  value,
  onChange,
  options = [],
  disabled,
}: ServicePackagesFieldProps) {
  return (
    <Form.Field name={name} asChild>
      <RadioGroup
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange as any}
      >
        <Form.Control asChild>
          <HiddenField name={name} value={(value || defaultValue || {}).name} />
        </Form.Control>

        <Form.Label asChild>
          <RadioGroup.Label className="sr-only">
            {i18n.en.fields.servicePackages.label}
          </RadioGroup.Label>
        </Form.Label>

        <ul className="grid w-full grid-cols-1 gap-6 sm:grid-cols-3">
          {options.map((opt, index) => (
            <li
              key={index}
              className="flex h-full flex-col items-start justify-end"
            >
              {opt.tags && (
                <div className="h-8 w-full">
                  <div className="flex gap-2">
                    {opt.tags.map((tag, index) => (
                      <Badge
                        key={index}
                        color={tag === 'recommend' ? 'orange' : 'green'}
                      >
                        {i18n.en.tags[tag]}
                      </Badge>
                    ))}
                  </div>
                </div>
              )}
              <RadioGroup.Option
                value={opt}
                className="focus-visible:outline-grass-9 w-full rounded-md"
              >
                {({ checked }) => (
                  <Card
                    className={
                      checked
                        ? 'outline-grass-9 w-full scale-105 transform-gpu outline outline-2 outline-offset-1'
                        : ''
                    }
                  >
                    <div className="flex items-center">
                      <div className="grid w-full gap-4 text-sm">
                        <RadioGroup.Label
                          className={`flex w-full justify-between gap-2 font-medium sm:flex-col ${
                            checked ? 'text-grass-9' : ''
                          }`}
                        >
                          <Text as="p">
                            <Strong>{opt.name}</Strong>
                          </Text>
                          <Text as="p" weight="bold" size="6">
                            {formatCurrency(opt.price as number, 'USD')}
                          </Text>
                        </RadioGroup.Label>
                        <Text>{opt.description}</Text>
                      </div>
                    </div>
                  </Card>
                )}
              </RadioGroup.Option>
            </li>
          ))}
        </ul>
      </RadioGroup>
    </Form.Field>
  );
}

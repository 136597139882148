export interface HedgeLogoProps {
  size?: number;
  width?: number;
  height?: number;
  color?: string;
  backgroundColor?: string;
}

export function HedgeLogo({
  size = 600,
  width = size,
  height = size,
  color = '#54BC52',
  backgroundColor = 'transparent',
}: HedgeLogoProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 600 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} fill={backgroundColor} />
      <path
        d="M167 79.5V341.5H424.5"
        stroke={color}
        strokeWidth="64"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M516 513L516 246.5L345.5 246.5"
        stroke={color}
        strokeWidth="64"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80 78L80 341.5"
        stroke={color}
        strokeWidth="64"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M270 166H516V83"
        stroke={color}
        strokeWidth="64"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M256 83L437 83"
        stroke={color}
        strokeWidth="64"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80 432L345 432"
        stroke={color}
        strokeWidth="64"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M424 432V512.5"
        stroke={color}
        strokeWidth="64"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M345 513H167"
        stroke={color}
        strokeWidth="64"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="256" cy="245.5" rx="30" ry="31.5" fill={color} />
      <ellipse cx="80" cy="512.5" rx="30" ry="31.5" fill={color} />
    </svg>
  );
}

import { useEffect, useMemo, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

export async function getAddressFromLatLang({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}) {
  const geocoder = new google.maps.Geocoder();

  const response = await geocoder.geocode({
    location: { lat: latitude, lng: longitude },
  });

  // return response.results[0].formatted_address;
  return response;
}

export async function getLatLang(address: string) {
  try {
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);

    return { lat, lng };
  } catch (error) {
    console.error('😱 Error: ', error);
  }
}

const libraries = ['places'] as any[];

export function useGoogleMapsPlacesAutocomplete() {
  const [selection, setSelection] = useState<string | null>(null);
  const {
    init,
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
  });

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
    libraries,
  });

  useEffect(() => {
    if (!isLoaded) return;
    init();
  }, [isLoaded, init]);

  return useMemo(
    () => ({
      ready,
      value,
      selection,
      onChange: setValue,
      onSelect: (address: string) => {
        setSelection(address);
        setValue(address, false);
        clearSuggestions();
      },
      suggestions:
        status === 'OK'
          ? data.map(({ description }: any) => ({
              value: description,
            }))
          : [],
    }),
    [ready, value, selection, setValue, clearSuggestions, status, data],
  );
}

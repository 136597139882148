import { useMemo } from 'react';
import {
  useGoogleMapsPlacesAutocomplete,
  getAddressFromLatLang,
  getLatLang,
} from './google';
import { useGeolocation } from './useGeolocation';

export function useLocation() {
  const { location, loading, getCurrentPosition, watchPosition } =
    useGeolocation();
  const { ready, value, selection, suggestions, onChange, onSelect } =
    useGoogleMapsPlacesAutocomplete();

  return useMemo(
    () => ({
      isLoading: !ready || loading,
      location,
      selection,
      value,
      suggestions,
      onChange,
      onSelect,
      getCurrentPosition,
      watchPosition,
      getCoordinatesFromAddress: (address: string | null = selection) =>
        address && getLatLang(address),
      getAddressFromCurrentPosition: () =>
        getCurrentPosition().then(async (location) => {
          const { results } = await getAddressFromLatLang(location as any);
          onSelect(results[0].formatted_address);
          return results[0].formatted_address;
        }),
    }),
    [
      ready,
      loading,
      location,
      selection,
      value,
      suggestions,
      onChange,
      onSelect,
      getCurrentPosition,
      watchPosition,
    ],
  );
}

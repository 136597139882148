export type CurrencyCode = 'USD' | 'EUR' | 'GBP' | 'JPY' | 'CAD' | 'AUD';

// Function to format a number with the appropriate currency symbol
export function formatCurrency(
  amount: number | string,
  currency: CurrencyCode = 'USD',
): string {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
  }).format(Number(amount));
}

// Function to format a date with the appropriate locale
export function formatDateWithLocale(date: Date, locale: string): string {
  return new Intl.DateTimeFormat(locale).format(date);
}

// Function to format a message with the appropriate locale
export function formatMessage(message: string, locale: string): string {
  // You can implement your own logic here to retrieve the translated message based on the locale
  return message;
}

export function prettyDate(date: Date) {
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
    timeStyle: 'short',
  }).format(date);
}

import type {
  ButtonHTMLAttributes,
  InputHTMLAttributes,
  PropsWithChildren,
} from 'react';
import { Children, forwardRef } from 'react';
import * as Form from '@radix-ui/react-form';
import { IconButton, Text, TextField, Tooltip } from '@/components';
import { NavigationIcon, SearchIcon } from 'lucide-react';
import { PROPERTY_ADDRESS_FIELD_NAME } from '@/constants/client/field-names';

type SizeType = '1' | '2' | '3';
type ColorType = string;

export const i18n = {
  en: {
    labels: {
      geolocate: 'Use my location',
    },
    fields: {
      search: {
        label: 'search',
        placeholder: '',
      },
    },
  },
};

export function SearchIconLabel({ label }: { label: string }) {
  return (
    <Form.Label asChild>
      <Text as="label" color="grass">
        <SearchIcon />
        <span className="sr-only">{label || i18n.en.fields.search.label}</span>
      </Text>
    </Form.Label>
  );
}

export interface SearchGeolocationButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ColorType;
  variant?: 'ghost' | 'solid';
  size?: SizeType;
}

// TODO: [a11y] fix tab order when in input
export function SearchGeolocationButton({
  variant = 'ghost',
  color,
  size,
  ...props
}: SearchGeolocationButtonProps) {
  // TODO: check permissions
  // TODO: use alert dialogue if not accepted
  // TODO: otherwise run functionality and reverse geocode, finally persist as address
  return (
    <Tooltip content={i18n.en.labels.geolocate} role="decorative">
      <IconButton
        color={color as any}
        size={size}
        variant={variant}
        type="button"
        aria-label={i18n.en.labels.geolocate}
        {...props}
      >
        <NavigationIcon size={16} />
      </IconButton>
    </Tooltip>
  );
}

export interface SearchFieldProps
  extends Omit<
    PropsWithChildren<InputHTMLAttributes<HTMLInputElement>>,
    'size'
  > {
  type: 'text' | 'search';
  size?: '1' | '2' | '3';
  variant?: 'surface' | 'classic' | 'soft';
  color?: ColorType;
  messages?: React.ReactNode[];
  label?: string;
}

export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
  function SearchFieldComponent(
    {
      color,
      size = '3',
      variant = 'surface',
      type = 'text',
      name = PROPERTY_ADDRESS_FIELD_NAME,
      placeholder = i18n.en.fields.search.placeholder,
      value,
      disabled,
      required,
      onChange,
      children,
      className,
      label,
      messages,
      ...props
    },
    ref,
  ) {
    const [start, end] = children ? Children.toArray(children) : [null, null];
    return (
      <Form.Field name={name} asChild className="flex flex-col gap-2">
        <div>
          {label ? (
            <div className="flex w-full items-end justify-between">
              <Form.Label asChild>
                <Text
                  className="mb-1 font-bold"
                  as="label"
                  weight="bold"
                  size="2"
                >
                  {label}
                </Text>
              </Form.Label>
            </div>
          ) : null}

          <Form.Control asChild>
            <TextField.Root
              size={size}
              variant={variant}
              // TODO: [typing] fix for color
              color={color as any}
              className={className}
              {...props}
              ref={ref}
              // TODO: use proper address autocomplete
              // autoComplete="billing street-address"
              type={type}
              onChange={onChange}
              value={value}
              disabled={disabled}
              required={required}
              placeholder={placeholder}
            >
              {start && <TextField.Slot>{start}</TextField.Slot>}
              {end && <TextField.Slot>{end}</TextField.Slot>}
            </TextField.Root>
          </Form.Control>

          {messages}
        </div>
      </Form.Field>
    );
  },
);

SearchField.displayName = 'SearchField';

import {
  forwardRef,
  type InputHTMLAttributes,
  type PropsWithChildren,
} from 'react';
import {
  Combobox as ReachCombobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  type ComboboxOptionProps,
  type ComboboxInputProps,
  type ComboboxProps as ReachComboboxProps,
} from '@reach/combobox';
import { Card, Button } from '@/components';

export interface ComboboxProps extends PropsWithChildren<ComboboxInputProps> {
  children?: any;
  // input props
  className: InputHTMLAttributes<HTMLInputElement>['className'];
  placeholder: InputHTMLAttributes<HTMLInputElement>['placeholder'];
  name: InputHTMLAttributes<HTMLInputElement>['name'];
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange'];
  // combobox props
  onSelect?: ReachComboboxProps['onSelect'];
  options: ComboboxOptionProps[];
  as?: any;
}

export const Combobox = forwardRef<HTMLInputElement, ComboboxProps>(
  function ComboboxComponent(
    { as, onSelect, options = [], children, ...props },
    ref,
  ) {
    return (
      <ReachCombobox onSelect={onSelect}>
        <ComboboxInput {...props} children={children} as={as} ref={ref} />
        {/* TODO: focus on panel or move scroll so input is on top, for mobile */}
        {/* don't make it jagged, honor prefer motion */}
        {options.length > 0 && (
          <ComboboxPopover portal={false} className="relative">
            <div className="absolute left-0 right-0 top-full mx-auto mt-2 w-full max-w-xl">
              <Card className="w-full p-2 backdrop-blur-2xl">
                <ComboboxList className="grid w-full gap-6 px-2">
                  {options.map(({ value }: ComboboxOptionProps, index) => (
                    <Button
                      asChild
                      key={index}
                      variant="ghost"
                      size="4"
                      className="w-full outline outline-2 outline-transparent [&[aria-selected=true]]:outline-grass-8 dark:[&[aria-selected=true]]:outline-grass-8"
                    >
                      <ComboboxOption value={value} index={index} key={index}>
                        <span className="me-auto w-full flex-1 text-start">
                          {value}
                        </span>
                      </ComboboxOption>
                    </Button>
                  ))}
                </ComboboxList>
              </Card>
            </div>
          </ComboboxPopover>
        )}
      </ReachCombobox>
    );
  },
);
Combobox.displayName = 'Combobox';

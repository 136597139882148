import { Button, Toggle } from '@/components';
import { PropsWithChildren } from 'react';

export interface ToggleButtonProps extends PropsWithChildren {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked?: boolean) => void;
}

export function ToggleButton({
  children,
  checked,
  defaultChecked,
  onChange,
}: ToggleButtonProps) {
  return (
    <Toggle
      pressed={checked}
      defaultPressed={defaultChecked}
      onPressedChange={onChange}
      asChild
      className="data-[state=on]:bg-grass--800 data-[state=on]:text-white"
    >
      <Button type="button" size="4" variant="soft">
        {children}
      </Button>
    </Toggle>
  );
}

export function ToggleGroup() {
  // TODO: implement
}

export function ToggleField() {
  // TODO: implement
  // treat as a checkbox
}

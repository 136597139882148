import type { FieldProps } from '@/components/forms/types';
import * as Form from '@radix-ui/react-form';
import { Text, Strong, TextField } from '@/components/radix';
import {
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
} from '@/constants/client/field-names';

const i18n = {
  en: {
    fields: {
      firstName: {
        label: 'First Name',
        placeholder: 'first name',
      },
      lastName: {
        label: 'Last Name',
        placeholder: 'last name',
      },
    },
  },
};

export interface NameFieldProps
  extends Omit<FieldProps<string>, 'checked' | 'defaulthChecked'> {}

export function NameField({
  name,
  label,
  placeholder,
  ...props
}: NameFieldProps) {
  return (
    <Form.Field
      name={name as string}
      className="group flex w-full flex-col gap-y-2"
    >
      <Form.Label asChild>
        <Text as="label" weight="bold" size="2">
          <Strong>{label}</Strong>
        </Text>
      </Form.Label>

      <Form.Control asChild>
        <TextField.Root
          variant="surface"
          size="3"
          type="text"
          placeholder={placeholder}
          {...props}
        ></TextField.Root>
      </Form.Control>
    </Form.Field>
  );
}

export function FirstNameField({
  name = FIRST_NAME_FIELD,
  label = i18n.en.fields.firstName.label,
  placeholder = i18n.en.fields.firstName.placeholder,
  ...props
}: NameFieldProps) {
  return (
    <NameField name={name} label={label} placeholder={placeholder} {...props} />
  );
}

export function LastNameField({
  name = LAST_NAME_FIELD,
  label = i18n.en.fields.lastName.label,
  placeholder = i18n.en.fields.lastName.placeholder,
  ...props
}: NameFieldProps) {
  return (
    <NameField name={name} label={label} placeholder={placeholder} {...props} />
  );
}

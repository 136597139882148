import { useMemo, useState } from 'react';

export const DEFAULT_STATE = {
  accuracy: null,
  altitude: null,
  altitudeAccuracy: null,
  heading: null,
  latitude: null,
  longitude: null,
  speed: null,
  timestamp: null,
};

export const DEFAULT_OPTIONS = {
  enableHighAccuracy: true,
  maximumAge: 0,
  timeout: Infinity,
};

export type GeolocationOptions = PositionOptions;
export type GeolocationError = GeolocationPositionError | null;
export type GeolocationPayload = GeolocationPosition['coords'] & {
  timestamp: GeolocationPosition['timestamp'];
};
export interface UseGeolocationReturnValue {
  loading: boolean;
  error: GeolocationError;
  location: GeolocationPayload | typeof DEFAULT_STATE;
  getCurrentPosition: (
    options?: GeolocationOptions,
  ) => Promise<GeolocationPosition>;
  watchPosition: (
    options?: GeolocationOptions,
  ) => ReturnType<typeof navigator.geolocation.watchPosition>;
}

export function createGeolocationPayload(
  event: GeolocationPosition,
): GeolocationPayload {
  return {
    accuracy: event.coords.accuracy,
    altitude: event.coords.altitude,
    altitudeAccuracy: event.coords.altitudeAccuracy,
    heading: event.coords.heading,
    latitude: event.coords.latitude,
    longitude: event.coords.longitude,
    speed: event.coords.speed,
    timestamp: event.timestamp,
  };
}

export function useGeolocation(
  options: GeolocationOptions = DEFAULT_OPTIONS,
): UseGeolocationReturnValue {
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<
    GeolocationPayload | typeof DEFAULT_STATE
  >(DEFAULT_STATE);
  const [error, setError] = useState<GeolocationError>(null);

  const { getCurrentPosition, watchPosition } = useMemo(() => {
    function onSuccess(event: GeolocationPosition) {
      setLocation(createGeolocationPayload(event));
    }

    function onError(error: GeolocationPositionError) {
      setError(error);
    }

    function getCurrentPosition(instanceOptions: GeolocationOptions = options) {
      return new Promise<GeolocationPayload>((resolve, reject) => {
        setLoading(true);
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLoading(false);
            onSuccess(position);
            resolve(createGeolocationPayload(position));
          },
          (error) => {
            setLoading(false);
            onError(error);
            reject(error);
          },
          instanceOptions,
        );
      });
    }

    function watchPosition(instanceOptions: GeolocationOptions = options) {
      const watchId = navigator.geolocation.watchPosition(
        onSuccess,
        onError,
        instanceOptions,
      );

      return function clearWatch() {
        return navigator.geolocation.clearWatch(watchId);
      };
    }

    return {
      getCurrentPosition,
      watchPosition,
    } as unknown as Pick<
      UseGeolocationReturnValue,
      'getCurrentPosition' | 'watchPosition'
    >;
  }, []);

  return useMemo<UseGeolocationReturnValue>(
    () => ({
      loading,
      error,
      location,
      getCurrentPosition,
      watchPosition,
    }),
    [error, location, loading, getCurrentPosition, watchPosition],
  );
}

import type { PropsWithChildren } from 'react';
import { radixColorScales } from '@radix-ui/themes';
import { Root, Submit } from '@radix-ui/react-form';

import type { FormProps } from '@/components/forms/types';
import { Button, Text, type ButtonProps } from '@/components/radix';

const i18n = {
  en: {
    actions: {
      submit: 'Save',
    },
  },
};

export interface SubmitButtonProps
  extends PropsWithChildren<Omit<ButtonProps, 'color'>> {
  disabled?: boolean;
  className?: string;
  color?: (typeof radixColorScales)[number];
}

export function SubmitButton({
  variant = 'solid',
  size = '4',
  children = i18n.en.actions.submit,
  className,
  ...props
}: SubmitButtonProps) {
  return (
    <div className="flex w-full justify-end">
      <Button
        {...props}
        type="submit"
        variant={variant}
        size={size}
        className={className || 'w-[160px] max-w-sm'}
        asChild
      >
        <Submit>
          <Text as="span" size="3" weight="bold">
            {children}
          </Text>
        </Submit>
      </Button>
    </div>
  );
}

export function FormSubmitButton(props: SubmitButtonProps) {
  return <SubmitButton {...props} />;
}

export function Form(props: FormProps) {
  return <Root {...props} />;
}

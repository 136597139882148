import { forwardRef } from 'react';
import { RadioGroup, type RadioGroupProps } from '@headlessui/react';
import { Title, Subtitle, Flex } from '@tremor/react';
import { Control, Field, Label } from '@radix-ui/react-form';
import { FEEDBACK_RATING_FIELD_NAME } from '@/constants/client/field-names';

const i18n = {
  en: {
    label: 'How do you feel about Hedge?',
    description:
      'Your input is valuable in helping us better understand you and tailor Hedge to cater to you.',
  },
};

// TODO: a11y quality improvements
const options = [
  {
    label: '😭',
    value: '1',
  },
  {
    label: '😔',
    value: '2',
  },
  {
    label: '😑',
    value: '3',
  },
  {
    label: '🙂',
    value: '4',
  },
  {
    label: '🥰',
    value: '5',
  },
];

export interface FeedbackRatingFieldProps extends RadioGroupProps<any, any> {
  name?: string;
  options: any[];
}

export const FeedbackRatingField = forwardRef<
  HTMLInputElement,
  FeedbackRatingFieldProps
>(function FeedbackRating(
  { name = FEEDBACK_RATING_FIELD_NAME, ...props },
  ref,
) {
  // TODO: use hidden field to persist value
  return (
    <Field name={name}>
      <Control asChild>
        <RadioGroup {...props} ref={ref}>
          <Label>
            <RadioGroup.Label>
              <Title>{i18n.en.label}</Title>
            </RadioGroup.Label>
            <RadioGroup.Description>
              <Subtitle>{i18n.en.description}</Subtitle>
            </RadioGroup.Description>
          </Label>

          <Flex className="my-6 max-w-md">
            {options.map((option, index) => (
              <RadioGroup.Option key={index} value={option.value}>
                {({ checked }) => (
                  <span
                    className={[
                      'rounded-full bg-green-200 p-2',
                      checked
                        ? 'border-spacing-2 border-8 border-green-300'
                        : '',
                    ].join(' ')}
                  >
                    {option.label}
                  </span>
                )}
              </RadioGroup.Option>
            ))}
          </Flex>
        </RadioGroup>
      </Control>
    </Field>
  );
});

FeedbackRatingField.displayName = 'FeedbackRatingField';

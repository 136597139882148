export function Copyrights() {
  return <span className="font-bold">© {new Date().getFullYear()} Hedge</span>;
}

export function Brand({ isCompact = false }: { isCompact?: boolean }) {
  return (
    <span
      className={`font-bold ${
        isCompact
          ? 'text-lg md:text-xl lg:text-2xl'
          : 'text-2xl md:text-3xl lg:text-4xl'
      }`}
    >
      Hedge<span className="text-green-500">.</span>
    </span>
  );
}

import type { ChangeEvent } from 'react';
import type { FieldProps } from '@/components/forms/types';
import { Text, Slider, Select, Strong } from '@/components/radix';
import * as Form from '@radix-ui/react-form';
import { Subtitle } from '@/components/typography';
import {
  MapPinIcon,
  RadiusIcon,
  ScissorsIcon,
  ShovelIcon,
  TractorIcon,
  WindIcon,
} from 'lucide-react';
import { useLocation } from '@/hooks/useLocation';
import {
  EmailField,
  type EmailFieldProps,
  PhoneNumberField,
  type PhoneNumberFieldProps,
} from './authentication';
import { NameField, type NameFieldProps } from './names';
import { HiddenField, type HiddenFieldProps } from './Hidden';
import { ToggleButton } from './ToggleButton';
import { SearchField, SearchGeolocationButton } from './Search';
import { Combobox } from './Combobox';

import {
  DEFAULT_TRAVEL_DISTANCE_MINUTES,
  TEAM_SIZE_MAX,
  TEAM_SIZE_MIN,
  TEAM_SIZE_STEP,
  TRAVEL_DISTANCE_MINUTES_MAX,
  TRAVEL_DISTANCE_STEP,
} from '@/constants/client/values';
import {
  COMPANY_NAME_FIELD,
  COMPANY_EMAIL_FIELD_NAME,
  COMPANY_PHONE_NUMBER_FIELD_NAME,
  COMPANY_ADDRESS_FIELD_NAME,
  POD_NAME_FIELD,
  TRAVEL_MODE_FIELD_NAME,
  TRAVEL_EXCLUSIONS_FIELD_NAME,
  TRAVEL_COVERAGE_IN_MINUTES_FIELD_NAME,
} from '@/constants/client/field-names';

const i18n = {
  en: {
    fields: {
      podName: {
        label: 'Pod Name',
        placeholder: 'pod name',
      },
      companyName: {
        label: 'Business Name',
        placeholder: 'company name',
      },
      companyEmail: {
        label: 'Business Email',
        placeholder: 'company email',
      },
      companyPhoneNumber: {
        label: 'Business Phone',
        placeholder: 'company phone number',
      },
      companyAddress: {
        label: 'Business Address',
        placeholder: 'Enter your company address',
      },
      serviceMatrix: {
        label: 'Services Provided',
        placeholder: 'What can you do?',
      },
      travelExclusions: {
        label: 'Travel Exclusions',
        placeholder: 'Select travel exclusions',
        options: ['TOLLS', 'HIGHWAY', 'FERRY'],
      },
      travelDistance: {
        label: 'Travel Distance',
        unit: 'minutes',
        conjugation: 'in',
      },
      teamSize: {
        label: 'Team Size',
      },
    },
    actions: {
      submit: 'Save',
    },
  },
};

export function CompanyNameField({
  name = COMPANY_NAME_FIELD,
  label = i18n.en.fields.companyName.label,
  placeholder = i18n.en.fields.companyName.placeholder,
  ...props
}: NameFieldProps) {
  return (
    <NameField name={name} label={label} placeholder={placeholder} {...props} />
  );
}

export function CompanyPhoneNumberField({
  name = COMPANY_PHONE_NUMBER_FIELD_NAME,
  label = i18n.en.fields.companyPhoneNumber.label,
  placeholder = i18n.en.fields.companyPhoneNumber.placeholder,
  ...props
}: PhoneNumberFieldProps) {
  return (
    <PhoneNumberField
      name={name}
      label={label}
      placeholder={placeholder}
      {...props}
    />
  );
}

export function CompanyEmailField({
  name = COMPANY_EMAIL_FIELD_NAME,
  label = i18n.en.fields.companyEmail.label,
  placeholder = i18n.en.fields.companyEmail.placeholder,
  ...props
}: EmailFieldProps) {
  return (
    <EmailField
      name={name}
      label={label}
      placeholder={placeholder}
      {...props}
    />
  );
}

export function CompanyAddressField({
  name = COMPANY_ADDRESS_FIELD_NAME,
  className,
  onChange: onChangeProp,
  onSelect: onSelectProp,
  ...props
}: NameFieldProps & {
  onChange?: (value: string) => void;
  onSelect?: (value: string) => void;
  className?: string;
}) {
  const {
    isLoading,
    value,
    suggestions,
    onChange,
    onSelect,
    getAddressFromCurrentPosition,
  } = useLocation();
  return (
    <Combobox
      as={SearchField}
      className={className}
      onSelect={(selection) => {
        onSelect(selection);
        onSelectProp && onSelectProp(selection);
      }}
      // @ts-ignore
      onChange={(event: ChangeEvent) => {
        onChange!((event.target as HTMLInputElement).value);
        onChangeProp && onChangeProp((event.target as HTMLInputElement).value);
      }}
      value={value}
      options={suggestions}
      name={name}
      placeholder={i18n.en.fields.companyAddress.placeholder}
      label={i18n.en.fields.companyAddress.label}
      {...props}
    >
      <MapPinIcon size={16} />
      <SearchGeolocationButton
        onClick={getAddressFromCurrentPosition}
        disabled={isLoading}
      />
    </Combobox>
  );
}

export interface TravelDistanceFieldProps extends FieldProps<number> {}

export function TravelDistanceField({
  defaultValue = DEFAULT_TRAVEL_DISTANCE_MINUTES,
  value,
  onChange,
}: TravelDistanceFieldProps) {
  return (
    <Form.Field
      name={TRAVEL_COVERAGE_IN_MINUTES_FIELD_NAME}
      className="flex flex-col gap-2"
    >
      <div className="flex w-full items-end justify-between">
        <Form.Label asChild>
          <Text className="mb-1 font-bold" as="label" size="2">
            <Strong>{i18n.en.fields.travelDistance.label}</Strong>
          </Text>
        </Form.Label>

        <Subtitle>
          {value || i18n.en.fields.travelDistance.conjugation}{' '}
          {i18n.en.fields.travelDistance.unit}
        </Subtitle>
      </div>

      <Form.Control asChild>
        <Slider
          defaultValue={[defaultValue]}
          max={TRAVEL_DISTANCE_MINUTES_MAX}
          step={TRAVEL_DISTANCE_STEP}
          min={TRAVEL_DISTANCE_STEP}
          value={value ? [value] : undefined}
          onValueChange={(values: number[]) => onChange && onChange(values[0])}
        />
      </Form.Control>
    </Form.Field>
  );
}

export interface TravelExclusionsFieldProps extends FieldProps<string> {}

export function TravelExclusionsField(props: TravelExclusionsFieldProps) {
  return (
    <Form.Field
      name={TRAVEL_EXCLUSIONS_FIELD_NAME}
      className="flex flex-col gap-2"
    >
      <div className="flex w-full items-end justify-between">
        <Form.Label asChild>
          <Text className="mb-1 font-bold" as="label" size="2">
            <Strong>{i18n.en.fields.travelExclusions.label}</Strong>
          </Text>
        </Form.Label>

        <Subtitle>{i18n.en.fields.travelExclusions.placeholder}</Subtitle>
      </div>

      <Form.Control asChild>
        <Select.Root {...props}>
          <Select.Trigger variant="soft">{props.value}</Select.Trigger>
          <Select.Content variant="soft">
            <Select.Item value="TOLLS">Tolls</Select.Item>
            <Select.Item value="HIGHWAY">Highway</Select.Item>
            <Select.Item value="FERRY">Ferry</Select.Item>
          </Select.Content>
        </Select.Root>
      </Form.Control>
    </Form.Field>
  );
}

export interface TravelModeFieldProps extends Omit<HiddenFieldProps, 'name'> {}

export function TravelModeField(props: TravelModeFieldProps) {
  return <HiddenField name={TRAVEL_MODE_FIELD_NAME} {...props} />;
}

export function PodNameField({
  name = POD_NAME_FIELD,
  label = i18n.en.fields.podName.label,
  placeholder = i18n.en.fields.podName.placeholder,
  ...props
}: NameFieldProps) {
  return (
    <NameField name={name} label={label} placeholder={placeholder} {...props} />
  );
}

export function PodServiceMatrixField() {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex w-full items-end justify-between">
        <Text className="mb-1 font-bold" as="label" size="2">
          <Strong>{i18n.en.fields.serviceMatrix.label}</Strong>
        </Text>

        <Subtitle>{i18n.en.fields.serviceMatrix.placeholder}</Subtitle>
      </div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        {[
          { Icon: WindIcon, label: 'Mowing' },
          { Icon: RadiusIcon, label: 'Edging' },
          { Icon: WindIcon, label: 'Clearing' },
          { Icon: ScissorsIcon, label: 'Trimming' },
          { Icon: ShovelIcon, label: 'Weeding' },
          { Icon: TractorIcon, label: 'Fertilizing' },
        ].map(({ label, Icon }, index) => (
          <ToggleButton key={index}>
            <Icon />
            {label}
          </ToggleButton>
        ))}
      </div>
    </div>
  );
}

export function PodServiceScheduleField() {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex w-full items-end justify-between">
        <Text className="mb-1 font-bold" as="label" size="2">
          <Strong>Service Schedule</Strong>
        </Text>

        <Subtitle>What days are you available?</Subtitle>
      </div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        {[
          { label: 'Monday' },
          { label: 'Tuesday' },
          { label: 'Wednesday' },
          { label: 'Thursday' },
          { label: 'Friday' },
          { label: 'Saturday' },
          { label: 'Sunday' },
        ].map(({ label }, index) => (
          <ToggleButton key={index}>{label}</ToggleButton>
        ))}
      </div>
    </div>
  );
}

export interface PodTeamSizeFieldProps extends FieldProps<number> {}

export function PodTeamSizeField({
  defaultValue = DEFAULT_TRAVEL_DISTANCE_MINUTES,
  value,
  onChange,
}: PodTeamSizeFieldProps) {
  return (
    <Form.Field
      name={TRAVEL_COVERAGE_IN_MINUTES_FIELD_NAME}
      className="flex flex-col gap-2"
    >
      <div className="flex w-full items-end justify-between">
        <Form.Label asChild>
          <Text className="mb-1 font-bold" as="label" size="2">
            <Strong>{i18n.en.fields.teamSize.label}</Strong>
          </Text>
        </Form.Label>

        <Subtitle>{value}</Subtitle>
      </div>

      <Form.Control asChild>
        <Slider
          defaultValue={[defaultValue]}
          step={TEAM_SIZE_STEP}
          min={TEAM_SIZE_MIN}
          max={TEAM_SIZE_MAX}
          value={value ? [value] : undefined}
          onValueChange={(values: number[]) => onChange && onChange(values[0])}
        />
      </Form.Control>
    </Form.Field>
  );
}

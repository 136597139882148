import { forwardRef } from 'react';

export interface HiddenFieldProps {
  name: string;
  value?: string;
  defaultValue?: string;
}

export const HiddenField = forwardRef<HTMLInputElement, HiddenFieldProps>(
  function HiddenField({ name, value, defaultValue }: HiddenFieldProps, ref) {
    return (
      <input
        ref={ref}
        type="hidden"
        name={name}
        value={value}
        defaultValue={defaultValue}
      />
    );
  },
);

HiddenField.displayName = 'HiddenField';
